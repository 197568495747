@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  position: relative;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
